import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AboutUs from "./components/AboutUs";
import Products from "./components/Products";
import Product from "./components/Product";
import Category from "./components/Category";
import LegalNotice from "./components/LegalNotice";
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ScrollToTop>
				<Routes>
					<Route path="/" element={<App />} />
					<Route path="products" element={<Products />} />
					<Route path="products/:productName" element={<Product />} />
					<Route path="categories/:categoryName" element={<Category />} />
					<Route path="about-us" element={<AboutUs />} />
					<Route path="mentions-legales" element={<LegalNotice />} />
					<Route
						path="*"
						element={
							<main style={{ padding: "1rem" }}>
								<p>There's nothing here!</p>
							</main>
						}
					/>
				</Routes>
			</ScrollToTop>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
