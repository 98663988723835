let products = [
    {
      name: "Reykjavik",
      subtitle: "SET DE 3 VALISES EN PET RECYCLE",
      category_name: "valise-rigides",
      category_display_name: "Valise rigides",
      reference: 201426,
      story: "Le nom de nos valises Green Tendance s’inspire des villes reconnues comme les plus saines au monde... Tour d\’horizon des destinations où l\’air est le plus pur : Reykjavik, Islande. Située sur la deuxième plus grande île d’Europe, l’Islande peut être fière d’être la destination la moins polluée au monde ! Située entre la Scandinavie et le Groenland, elle est l’unique pays sur terre à produire une électricité 100% renouvelable. Avec ses 360000 habitants, la majorité du territoire est recouvert d’étendues naturelles vierges. L’endroit idéal pour des vacances à l’air pur !",
      principal_photo: 'images/201426/201426-3 bleu metal SE.png',
      colors: ['anthracite', 'bleu-metal', 'brique', 'marine', 'noir', 'rouge', 'vert-kiwi'],
      dimensions : [
        {
          format: 'S',
          weight: '2.90',
          volume: '36',
          size: '52x23x32'
        },
        {
          format: 'M',
          weight: '3.80',
          volume: '72',
          size: '65x31x39'
        },
        {
          format: 'L',
          weight: '4.80',
          volume: '111',
          size: '75x35x46'
        },
      ],
      features: [
        'Poignée télescopique ajustable en hauteur',
        'Poignées de portage sur le dessus et sur le côté',
        'Cadenas TSA à code latéral',
        '4 roues 360°',
        'Intérieur entièrement doublé',
        'Extensible de 5 cm sur la taille M et L.'
      ],
      arguments: [
        {
          title: "Equipement intérieur",
          description : "2 poches, 1 compartiment zippé et 2 sangles pour un rangement optimal.",
          picture: "/images/201426/equipement.png"
        },
        {
          title: "Roues 360°",
          description : "Système optimal pour permettre une direction stable et sans effort et silencieuse.",
          picture: "/images/201426/roues.png"
        },
        {
          title: "Système télescopique",
          description : "Légéreté et resistance. Conçu pour permettre un ré- glage en hauteur et offrir un maximum de confort et une manœuvrabilité optimale.",
          picture: "/images/201426/poignee.png"
        },
        {
          title: "Cadenas",
          description : "Equipée de cadenas TSA qui peut être ouvert sans dommage lors du contrôle des bagages.",
          picture: "/images/201426/cadenas.png"
        },
      ],
      variants : [
        {
          color: 'Anthracite',
          url_rewrite: 'anthracite',
          principal_photo: '/images/201426/201426-3 anthracite.png',
          pictures: [
            '/images/201426/201426-3 anthracite.png',
            '/images/201426/201426-3 anthracite B.png',
            '/images/201426/201426-3 anthracite C.png',
            '/images/201426/201426-3 anthracite D.png',
          ]
        },
        {
          color: 'Bleu métal',
          url_rewrite: 'bleu-metal',
          principal_photo: '/images/201426/201426-3 bleu metal SE.png',
          pictures: [
            '/images/201426/201426-3 bleu metal A.png',
            '/images/201426/201426-3 bleu metal B.png',
            '/images/201426/201426-3 bleu metal C.png',
            '/images/201426/201426-3 bleu metal D.png',
          ]
        },
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/201426/201426-3 brique.png',
          pictures: [
            '/images/201426/201426-3 brique.png',
            '/images/201426/201426-3 brique B.png',
            '/images/201426/201426-3 brique C.png',
            '/images/201426/201426-3 brique D.png',
          ]
        },
        {
          color: 'Marine',
          url_rewrite: 'marine',
          principal_photo: '/images/201426/201426-3 marine.png',
          pictures: [
            '/images/201426/201426-3 marine.png',
            '/images/201426/201426-3 marine.png',
            '/images/201426/201426-3 marine  C.png',
            '/images/201426/201426-3 marine B.png',
            '/images/201426/201426-3 marine C.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/201426/201426-3 noir.png',
          pictures: [
            '/images/201426/201426-3 noir A.png',
            '/images/201426/201426-3 noir B.png',
            '/images/201426/201426-3 noir C.png',
            '/images/201426/201426-3 noir D.png',
          ]
        },
        {
          color: 'Rouge',
          url_rewrite: 'rouge',
          principal_photo: '/images/201426/201426-3 rouge.png',
          pictures: [
            '/images/201426/201426-3 rouge.png',
            '/images/201426/201426-3 rouge metal B.png',
            '/images/201426/201426-3 rouge metal C.png',
            '/images/201426/201426-3 rouge metal D.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/201426/201426-3 vert kiwi.png',
          pictures: [
            '/images/201426/201426-3 vert kiwi A.png',
            '/images/201426/201426-3 vert kiwi B.png',
            '/images/201426/201426-3 vert kiwi C.png',
            '/images/201426/201426-3 vert kiwi D.png',
          ]
        },
      ]
    },
    {
      name: "Wellington",
      subtitle: "VALISE CABINE PET RECYCLE",
      category_name: "valise-souples",
      category_display_name: "Valise souples",
      reference: 250026,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250026/250026-1 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi', 'bleu', 'gris', 'rouge'],
      dimensions : [
        {
          format: 'S',
          weight: '2.10',
          volume: '37.4',
          size: '40x55x23'
        },
      ],
      features: [
        'Poignée télescopique ajustable en hauteur',
        'Poignées de portage sur le dessus et sur le côté',
        '4 roues',
        'Poche avant',
        'Intérieur entièrement doublé',
        'Compartiment ordinateur sur le devant',
        'Peu encombrant avec des dimensions adaptées et acceptées par la majorité des compagnies aériennes.'
      ],
      arguments: [
        {
          title: "Volume maxi",
          description : "Grande capacité intérieure organisé avec sangles et pochettes.",
          picture: "/images/250026/volume.png"
        },
        {
          title: "8 roues",
          description : "Roues pivotantes silencieuses pour une grande stabilité et maniabilité.",
          picture: "/images/250026/roues.png"
        },
        {
          title: "Système télescopique",
          description : "Légéreté, resistance. et confort. Bouton poussoir pour permettre un réglage en hauteur.",
          picture: "/images/250026/poignee.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250026/250026-1 brique.png',
          pictures: [
            '/images/250026/250026-1 brique.png',
            '/images/250026/250026-1 brique B.png',
            '/images/250026/250026-1 brique C.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250026/250026-1 noir.png',
          pictures: [
            '/images/250026/250026-1 noir.png',
            '/images/250026/250026-1 noir B.png',
            '/images/250026/250026-1 noir C.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250026/250026-1 vert kiwi.png',
          pictures: [
            '/images/250026/250026-1 vert kiwi.png',
            '/images/250026/250026-1 vert kiwi B.png',
            '/images/250026/250026-1 vert kiwi C.png',
          ]
        },
        {
          color: 'Bleu',
          url_rewrite: 'bleu',
          principal_photo: '/images/250026/250026-1 bleu SE.png',
          pictures: [
            '/images/250026/250026-1 bleu SE.png',
          ]
        },
        {
          color: 'Gris',
          url_rewrite: 'gris',
          principal_photo: '/images/250026/250026-1 gris SE.png',
          pictures: [
            '/images/250026/250026-1 gris SE.png',
          ]
        },
        {
          color: 'Rouge',
          url_rewrite: 'rouge',
          principal_photo: '/images/250026/250026-1 rouge SE.png',
          pictures: [
            '/images/250026/250026-1 rouge SE.png',
          ]
        },
      ]
    },
    {
      name: "Trousse de toilette",
      subtitle: "TROUSSE DE TOILETTE",
      category_name: "accessoires",
      category_display_name: "Accessoires",
      reference: 250013,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250013/250013 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi'],
      dimensions : [
        {
          format: 'S',
          weight: '0.160',
          volume: '4.20',
          size: '21x16x11'
        },
      ],
      features: [
        'Trousse ultra légerère',
        'Doublure intérieure de protection',
        'Poche centrale intérieure',
        'Poche plate sur le devant',
        'Anse de portage',
        'Polyester recyclé résistant aux déchirures et à l’humidité.'
      ],
      arguments: [
        {
          title: "Ultra légère",
          description : "Conception solide et ultra légère das un matériau de qualité.",
          picture: "/images/heavy.png"
        },
        {
          title: "Prise en main facile",
          description : "Poignée de portage latérale renforcée.",
          picture: "/images/handtake.png"
        },
        {
          title: "Détails élégants",
          description : "Design sobre et moderne avec une étiquette en tissu reprenant le nom de la collection cousue sur le devant.",
          picture: "/images/details.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250013/250013 brique.png',
          pictures: [
            '/images/250013/250013 brique.png',
            '/images/250013/250013 brique B.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250013/250013 noir.png',
          pictures: [
            '/images/250013/250013 noir.png',
            '/images/250013/250013 noir B.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250013/250013 vert kiwi.png',
          pictures: [
            '/images/250013/250013 vert kiwi.png',
            '/images/250013/250013 vert kiwi B.png',
          ]
        },
      ]
    },
    {
      name: "Trousse à suspendre",
      subtitle: "TROUSSE A SUSPENDRE",
      category_name: "accessoires",
      category_display_name: "Accessoires",
      reference: 250012,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250012/250012 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi', 'gris', 'rouge'],
      dimensions : [
        {
          format: 'S',
          weight: '0.160',
          volume: '4.20',
          size: '24x16x11'
        },
      ],
      features: [
        'Trousse fourre-tout idéal pour les petits voyages',
        '2 compartiments un en maille filet et l’autre doté d’une doublure imperméable',
        '1 poche intérieure zippée | 2 poches extérieures',
        '1 poignée de portage',
        'Polyester recyclé.'
      ],
      arguments: [
        {
          title: "Organisation optimale",
          description : "Spacieux, son aménagement intérieur permet également de ranger flacons de gel douche, de shampoing ou de déodorant. 2 poches.",
          picture: "/images/heavy.png"
        },
        {
          title: "Astucieux",
          description : "Equipé d’un crochet pour la suspendre partout.",
          picture: "/images/portage.png"
        }
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250012/250012 brique.png',
          pictures: [
            '/images/250012/250012 brique.png',
            '/images/250012/250012 brique B.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250012/250012 noir.png',
          pictures: [
            '/images/250012/250012 noir.png',
            '/images/250012/250012 noir B.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250012/250012 vert kiwi.png',
          pictures: [
            '/images/250012/250012 vert kiwi.png',
            '/images/250012/250012 vert kiwi B.png',
          ]
        },
        {
          color: 'Gris',
          url_rewrite: 'gris',
          principal_photo: '/images/250012/250012 gris.png',
          pictures: [
            '/images/250012/250012 gris.png',
          ]
        },
        {
          color: 'Rouge',
          url_rewrite: 'rouge',
          principal_photo: '/images/250012/250012 rouge.png',
          pictures: [
            '/images/250012/250012 rouge.png',
          ]
        },
      ]
    },
    {
      name: "Vanity case souple",
      subtitle: "VANITY CASE SOUPLE",
      category_name: "accessoires",
      category_display_name: "Accessoires",
      reference: 250011,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250011/250011 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi'],
      dimensions : [
        {
          format: 'S',
          weight: '0.400',
          volume: '14',
          size: '29x23x21'
        },
      ],
      features: [
        'Trousse fonctionnelle équipée d’un passant à l’arrière',
        'Bretelles réglables',
        'Poignée de portage',
        'Sangles de maintien à l’intérieur',
        'Large poche intérieure et une poche zippée',
        'Doublure intérieure de protection',
        'Polyester recyclé.'
      ],
      arguments: [
        {
          title: "Astucieux",
          description : "Passant à l’arrière permettant de la placer sur le dessus de la valise de façon stable.",
          picture: "/images/heavy.png"
        },
        {
          title: "Organisation optimale",
          description : "Large poche intérieure et sangles de maintien sur les côtés permettant de ranger verticalement ses produits de soin.",
          picture: "/images/portage.png"
        },
        {
          title: "Portage facilité",
          description : "Peut se porter à la main ou en bandoulière et à l’épaule grâce à ses bretelles réglables.",
          picture: "/images/portage.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250011/250011 brique.png',
          pictures: [
            '/images/250011/250011 brique.png',
            '/images/250011/250011 brique B.png',
            '/images/250011/250011 brique C.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250011/250011 noir.png',
          pictures: [
            '/images/250011/250011 noir.png',
            '/images/250011/250011 noir B.png',
            '/images/250011/250011 noir C.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250011/250011 vert kiwi.png',
          pictures: [
            '/images/250011/250011 vert kiwi.png',
            '/images/250011/250011 vert kiwi B.png',
            '/images/250011/250011 vert kiwi C.png',
          ]
        },
      ]
    },
    {
      name: "Valise cabine 3 en 1",
      subtitle: "VALISE CABINE 3 EN 1",
      category_name: "sacs",
      category_display_name: "Sacs",
      reference: 250060,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250060/250060 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi'],
      dimensions : [
        {
          format: 'S',
          weight: '0.750',
          volume: '44',
          size: '37x51x20'
        },
      ],
      features: [
        'Valise ultra légerère',
        'Se porte en sac à dos, à la main ou à l’épaule',
        'Doublure intérieure de protection',
        'Deux compartiments principaux avec filet',
        'Fermeture zippée',
        'Poche plate frontale',
        'Poche plate frontale',
        'Anse et bretelles de portage',
        'Polyester recyclé.'
      ],
      arguments: [
        {
          title: "Ultra légère",
          description : "Conception solide et ultra légère das un matériau de qualité.",
          picture: "/images/heavy.png"
        },
        {
          title: "Multiportage",
          description : "Peut se porter en sac à dos (bretelles rétractables), à la main ou en bandoulière (amovible).",
          picture: "/images/portage.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250060/250060 brique.png',
          pictures: [
            '/images/250060/250060 brique.png',
            '/images/250060/250060 brique B.png',
            '/images/250060/250060 brique C.png',
            '/images/250060/250060 brique D.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250060/250060 noir.png',
          pictures: [
            '/images/250060/250060 noir.png',
            '/images/250060/250060 noir B.png',
            '/images/250060/250060 noir C.png',
            '/images/250060/250060 noir D.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250060/250060 vert kiwi.png',
          pictures: [
            '/images/250060/250060 vert kiwi.png',
            '/images/250060/250060 vert kiwi B.png',
            '/images/250060/250060 vert kiwi C.png',
            '/images/250060/250060 vert kiwi D.png',
          ]
        },
      ]
    },
    {
      name: "Sac de voyage cabine 40cm",
      subtitle: "SAC DE VOYAGE CABINE 40CM",
      category_name: "sacs",
      category_display_name: "Sacs",
      reference: 250040,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250040/250040 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi', 'rouge', 'gris', 'bleu'],
      dimensions : [
        {
          format: 'S',
          weight: '0.450',
          volume: '21.30',
          size: '40x25x20'
        },
      ],
      features: [
        'Sac ultra léger',
        'Doublure intérieure de protection',
        'Poignée pratique et bandoulière réglable en longueur',
        'Se transforme en sac à dos',
        'Deux compartiments principaux',
        'Passant pour maintien stable sur la valise',
        'Poche plate frontale',
        'Polyester recyclé.'
      ],
      arguments: [
        {
          title: "Transport facilité",
          description : "Peut se glisser sur la poignée télescopique d’une valise.",
          picture: "/images/transport facility.png"
        },
        {
          title: "Dimensions idéales",
          description : "Parfait pour être rangé sous le siège du train, avion, bus... Accepté en cabine par toutes les compagnies low-cost.",
          picture: "/images/dimensions.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250040/250040 brique.png',
          pictures: [
            '/images/250040/250040 brique.png',
            '/images/250040/250040 brique B.png',
            '/images/250040/250040 brique C.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250040/250040 noir.png',
          pictures: [
            '/images/250040/250040 noir.png',
            '/images/250040/250040 noir B.png',
            '/images/250040/250040 noir C.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250040/250040 vert kiwi.png',
          pictures: [
            '/images/250040/250040 vert kiwi.png',
            '/images/250040/250040 vert kiwi B.png',
            '/images/250040/250040 vert kiwi C.png',
          ]
        },
        {
          color: 'Rouge',
          url_rewrite: 'rouge',
          principal_photo: '/images/250040/250040 rouge.png',
          pictures: [
            '/images/250040/250040 rouge.png',
          ]
        },
        {
          color: 'Gris',
          url_rewrite: 'gris',
          principal_photo: '/images/250040/250040 gris.png',
          pictures: [
            '/images/250040/250040 gris.png',
          ]
        },
        {
          color: 'Bleu',
          url_rewrite: 'bleu',
          principal_photo: '/images/250040/250040 bleu.png',
          pictures: [
            '/images/250040/250040 bleu.png',
          ]
        },
      ]
    },
    {
      name: "Sac de voyage cabine 50cm",
      subtitle: "SAC DE VOYAGE CABINE 50CM",
      category_name: "sacs",
      category_display_name: "Sacs",
      reference: 250050,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250050/250050 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi', 'rouge', 'marine', 'gris'],
      dimensions : [
        {
          format: 'S',
          weight: '0.950',
          volume: '46.50',
          size: '50x31x30'
        },
      ],
      features: [
        'Sac ultra léger',
        'Doublure intérieure de protection',
        'Poignée pratique et bandoulière réglable en longueur',
        'Deux compartiments principaux',
        'Passant pour maintien stable sur la valise',
        'Fermeture zippée',
        'Poche plate frontale',
        'Polyester recyclé.'
      ],
      arguments: [
        {
          title: "Fonctionnel",
          description : "Possède une large capacité de range- ment ainsi qu’une poche plate zippée devant.",
          picture: "/images/transport facility.png"
        },
        {
          title: "Transport facilité",
          description : "Peut se glisser sur la poignée télescopique d’une valise.",
          picture: "/images/transport facility.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250050/250050 brique.png',
          pictures: [
            '/images/250050/250050 brique.png',
            '/images/250050/250050 brique B.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250050/250050 noir.png',
          pictures: [
            '/images/250050/250050 noir.png',
            '/images/250050/250050 noir B.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250050/250050 vert kiwi.png',
          pictures: [
            '/images/250050/250050 vert kiwi.png',
            '/images/250050/250050 vert kiwi B.png',
          ]
        },
        {
          color: 'Rouge',
          url_rewrite: 'rouge',
          principal_photo: '/images/250050/250050 rouge.png',
          pictures: [
            '/images/250050/250050 rouge.png',
          ]
        },
        {
          color: 'Marine',
          url_rewrite: 'marine',
          principal_photo: '/images/250050/250050 marine.png',
          pictures: [
            '/images/250050/250050 marine.png',
          ]
        },
        {
          color: 'Gris',
          url_rewrite: 'gris',
          principal_photo: '/images/250050/250050 gris.png',
          pictures: [
            '/images/250050/250050 gris.png',
          ]
        },
      ]
    },
    {
      name: "Sac shopper",
      subtitle: "SAC SHOPPER",
      category_name: "sacs",
      category_display_name: "Sacs",
      reference: 250014,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250014/250014 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi'],
      dimensions : [
        {
          format: 'S',
          weight: '0.400',
          volume: '20',
          size: '30x30x17'
        },
      ],
      features: [
        'Sac ultra léger',
        'Poignée double et bandoulière réglable en longueur',
        'Passant pour maintien stable sur la valise',
        'Un compartiment principal',
        '2 pochettes intérieures',
        '1 poche zippée',
        'Poche plate frontale',
        'Polyester recyclé'
      ],
      arguments: [
        {
          title: "Forme original",
          description : "Un modèle inspiré par la traditionnelle sacoche de médecin, conçu pour tous les types de déplacement.",
          picture: "/images/original-form.png"
        },
        {
          title: "Transport facilité",
          description : "Peut se glisser sur la poignée télescopique d’une valise.",
          picture: "/images/transport facility.png"
        },
        {
          title: "Multi-usages",
          description : "Idéal aussi bien pour les voyages, au bureau ou pour faire son shopping.",
          picture: "/images/multiusage.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250014/250014 brique.png',
          pictures: [
            '/images/250014/250014 brique.png',
            '/images/250014/250014 brique B.png',
            '/images/250014/250014 brique C.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250014/250014 noir.png',
          pictures: [
            '/images/250014/250014 noir.png',
            '/images/250014/250014 noir B.png',
            '/images/250014/250014 noir C.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250014/250014 vert kiwi.png',
          pictures: [
            '/images/250014/250014 vert kiwi.png',
            '/images/250014/250014 vert kiwi B.png',
            '/images/250014/250014 vert kiwi C.png',
          ]
        },
      ]
    },
    {
      name: "Sac de voyage GM",
      subtitle: "SAC DE VOYAGE GM",
      category_name: "sacs",
      category_display_name: "Sacs",
      reference: 250015,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250015/250015 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi'],
      dimensions : [
        {
          format: 'S',
          weight: '0.800',
          volume: '73.50',
          size: '70x35x30'
        },
      ],
      features: [
        'Sac ultra léger',
        'Doublure intérieure de protection',
        'Poche centrale intérieure',
        'Poche plate sur le devant',
        'Poche latérale pratique pour ranger les chaussures ou autres',
        'Fond équipé de support de protection',
        'Polyester recyclé'
      ],
      arguments: [
        {
          title: "Resistant",
          description : "Avec poignée et bandoulière pour un transport facile jusqu’à 15kg. Le sac idéal pour le sport ou le tourisme.",
          picture: "/images/original-form.png"
        },
        {
          title: "Mulifonctions",
          description : "Peut s’utiliser pour des déplacements en transport, pour la salle de sport...",
          picture: "/images/multifonctions.png"
        },
        {
          title: "Détail pratique",
          description : "Un compartiment sur le côté pour y ranger chaussures, affaires de sport, linge mouillé...",
          picture: "/images/detail-pratique.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250015/250015 brique.png',
          pictures: [
            '/images/250015/250015 brique.png',
            '/images/250015/250015 brique B.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250015/250015 noir.png',
          pictures: [
            '/images/250015/250015 noir.png',
            '/images/250015/250015 noir B.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250015/250015 vert kiwi.png',
          pictures: [
            '/images/250015/250015 vert kiwi.png',
            '/images/250015/250015 vert kiwi B.png',
          ]
        },
      ]
    },
    {
      name: "Sac de voyage à roulettes",
      subtitle: "SAC DE VOYAGE A ROULETTES",
      category_name: "sacs",
      category_display_name: "Sacs",
      reference: 250025,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250025/250025 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi'],
      dimensions : [
        {
          format: 'S',
          weight: '2.30',
          volume: '72.80',
          size: '65x35x32'
        },
      ],
      features: [
        'Sac pratique et polyvalent',
        'Doublure intérieure de protection',
        'Poche centrale intérieure',
        'Poche plate extérieure sur toute la longueur du sac',
        'Fond renforcé avec pieds et supports',
        '2 poignées de portage',
        '2 roues',
        'Bandoulière réglable',
        'Polyester recyclé.'

      ],
      arguments: [
        {
          title: "Confortable",
          description : "Equipé d’une poignée télescopique réglable de 2 roulettes.",
          picture: "/images/original-form.png"
        },
        {
          title: "Capacité maximale",
          description : "Peut contenir jusqu’à 72 litres. Foctionnel avec ses nombreuses poches et son aménagement intérieur complet.",
          picture: "/images/multifonctions.png"
        },
        {
          title: "Portage facilité",
          description : "3 possibilité de portage : main, épaule ou ban- doulière.",
          picture: "/images/detail-pratique.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250025/250025 brique.png',
          pictures: [
            '/images/250025/250025 brique.png',
            '/images/250025/250025 brique B.png',
            '/images/250025/250025 SE brique.png',
            '/images/250025/250025 brique C.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250025/250025 noir.png',
          pictures: [
            '/images/250025/250025 noir.png',
            '/images/250025/250025 noir B.png',
            '/images/250025/250025 SE noir.png',
            '/images/250025/250025 noir C.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250025/250025 vert kiwi.png',
          pictures: [
            '/images/250025/250025 vert kiwi.png',
            '/images/250025/250025 vert kiwi B.png',
            '/images/250025/250025 SE vert kiwi.png',
            '/images/250025/250025 vert kiwi C.png',
          ]
        },
      ]
    },
    {
      name: "Sac à dos",
      subtitle: "SAC A DOS",
      category_name: "sacs",
      category_display_name: "Sacs",
      reference: 250055,
      story: 'Wellington, Nouvelle-Zélande... Aussi appelée “la capitale venteuse”, cette ville a développé un grand parc éolien devenu une attraction, avec balade pédestre et point de vue exceptionnel. Wellington a pour objectif de en pro- duire 90% sa consommation électrique à partir d’énergies renouvelables. Son niveau de pollution est enviable : air pur, eaux propres, sols nettoyés... Depuis quelques années, la ville est parvenue à réduire ses émissions de CO2 de 2% en connaissant pourtant une augmentation de la population de 21%.',
      principal_photo: 'images/250055/250055 vert kiwi.png',
      colors: ['brique', 'noir', 'vert-kiwi', 'bleu', 'gris', 'rouge'],
      dimensions : [
        {
          format: 'S',
          weight: '0.400',
          volume: '26.6',
          size: '31x41x21'
        },
      ],
      features: [
        'Compagnon pratique et léger pour le voyage, l’école et les loisirs',
        'Doublure intérieure de protection',
        'Plusieurs poches intérieures dont une poche matelassée pour l’ordinateur',
        '2 poches sur le devant',
        'Bretelles réglables',
        'Polyester recyclé.'

      ],
      arguments: [
        {
          title: "Confortable",
          description : "Equipé d’une poignée télescopique réglable de 2 roulettes.",
          picture: "/images/original-form.png"
        },
        {
          title: "Capacité maximale",
          description : "Peut contenir jusqu’à 72 litres. Foctionnel avec ses nombreuses poches et son aménagement intérieur complet.",
          picture: "/images/multifonctions.png"
        },
        {
          title: "Portage facilité",
          description : "3 possibilité de portage : main, épaule ou ban- doulière.",
          picture: "/images/detail-pratique.png"
        },
      ],
      variants : [
        {
          color: 'Brique',
          url_rewrite: 'brique',
          principal_photo: '/images/250055/250055 brique.png',
          pictures: [
            '/images/250055/250055 brique.png',
          ]
        },
        {
          color: 'Noir',
          url_rewrite: 'noir',
          principal_photo: '/images/250055/250055 noir.png',
          pictures: [
            '/images/250055/250055 noir.png',
            '/images/250055/250055 noir B.png',
          ]
        },
        {
          color: 'Vert kiwi',
          url_rewrite: 'vert-kiwi',
          principal_photo: '/images/250055/250055 vert kiwi.png',
          pictures: [
            '/images/250055/250055 vert kiwi.png',
          ]
        },
        {
          color: 'Bleu',
          url_rewrite: 'bleu',
          principal_photo: '/images/250055/250055 bleu.png',
          pictures: [
            '/images/250055/250055 bleu.png',
          ]
        },
        {
          color: 'Gris',
          url_rewrite: 'gris',
          principal_photo: '/images/250055/250055 gris.png',
          pictures: [
            '/images/250055/250055 gris.png',
          ]
        },
        {
          color: 'Rouge',
          url_rewrite: 'rouge',
          principal_photo: '/images/250055/250055 rouge.png',
          pictures: [
            '/images/250055/250055 rouge.png',
          ]
        },
      ]
    },
  ];

  let categories = [
    {
      name: "Valises rigides",
      url_rewrite: "valise-rigides",
      principal_photo: 'images/groupe3.png',
    },
    {
      name: "Valises souples",
      url_rewrite: "valise-souples",
      principal_photo: 'images/groupe4.png',
    },
    {
      name: "Sacs",
      url_rewrite: "sacs",
      principal_photo: 'images/groupe2.png',
    },
    {
      name: "Accessoires",
      url_rewrite: "accessoires",
      principal_photo: 'images/groupe5.png',
    },
  ];
  
  export function getProducts() {
    return products;
  }

  export function getProduct(name) {
    return products.find(
      product => product.name === name
    );
  }

  export default function getProductVariant(name, variant) {
    var product = products.find(
      product => product.name === name
    );

    for (let index = 0; index < product.variants.length; index++) {
      const element = product.variants[index];
      if(product.variants[index]['url_rewrite'] == variant) {
        return product.variants[index];
      }
    }
  }

  export function getCategories() {
    return categories;
  }

  export function getCategory(url_rewrite) {
    return categories.find(
      category => category.url_rewrite === url_rewrite
    );
  }

  export function getCategoryProducts(name) {
    var category_products = [];
    
    for (let index = 0; index < products.length; index++) {
      const element = products[index];
      if(element['category_name'] == name) {
        category_products.push(element)
      } 
    }

    return category_products;
  }