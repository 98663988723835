import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import carousel from "../assets/images/hero-background.png";
import environnement from "../assets/images/environnement.png";
import gogreen from "../assets/images/go-green.png";
import baroudeur from "../assets/images/baroudeur.png";
import globetrotteur from "../assets/images/globetrotteur.png";
import touriste from "../assets/images/touriste.png";
import weekend from "../assets/images/weekend.png";
import { getCategories, getProducts } from "../assets/data";

function Home() {
	let products = getProducts();
	let categories = getCategories();
	return (
		<div>
			<div className="container">
				<div className="banner offset-none">
					<img src={carousel} alt="carousel-photo" className="carousel-photo" />
					<div className="banner-content left">
						<h1 className="font-bold mb-5">
							<span className="text-shadow color-none">Green</span> Travel
						</h1>
						<h4 className="mb-10">
							Bagages et accessoires de mobilité éco-friendly
						</h4>
						<Link className="hidden-mobile action default" to="/">
							En savoir plus
						</Link>
					</div>
				</div>
			</div>

			<div className="container three-arguments">
				<div className="argument mt-10">
					<img
						src={environnement}
						alt="environnement-logo"
						className="argument-img"
					/>
					<h3 className="argument-title font-bold">Recyclé</h3>
					<p className="argument-phrase">
						Des valises fabriquées à partir de bouteilles plastique recyclées
						post consommation à 85% minimum.{" "}
					</p>
				</div>
				<div className="argument mt-10">
					<img
						src={environnement}
						alt="environnement-logo"
						className="argument-img"
					/>
					<h3 className="argument-title font-bold">Recyclable</h3>
					<p className="argument-phrase">
						Des trousses et sacs de voyage avec un revêtement extérieur en tissu
						100% recyclé{" "}
					</p>
				</div>
				<div className="argument mt-10">
					<img
						src={environnement}
						alt="environnement-logo"
						className="argument-img"
					/>
					<h3 className="argument-title font-bold">Qualité certifiée</h3>
					<p className="argument-phrase">Aucun compromis sur la qualité. </p>
				</div>
			</div>

			<div className="m-50">
				<div className="quality">
					<div className="quality-width">
						<div className="text-center p-10">
							<h3>Qualité, couleur & fonctionnalité</h3>
						</div>
						<div className="quality-text">
							<p>
								Fondée en 2008, France Bag est une entreprise familiale
								française spécialisée dans le bagage et les accessoires de
								mobilité.
							</p>

							<p>
								Si nous pensons fonctionnalité et qualité, nous savons que les
								consommateurs sont à la recherche de produits différenciants.
							</p>

							<p>
								C’est pourquoi, chez France Bag, nous restons attentifs aux
								tendances et innovations pour proposer des produits toujours
								plus cohérents.
							</p>

							<p>
								Une forte expertise dans le domaine de la mobilité, une
								puissance logistique et une équipe engagée nous permettent de
								répondre aux différents attentes des clients.
							</p>
						</div>
					</div>
					
						<img src={gogreen} alt="go-green-photo" className="go-green-img" />
					
				</div>

				<div className="container space-div">
					{categories.map((category) => (
						<Link
							to={`/categories/${category.url_rewrite}`}
							className="banner hovered card-category"
						>
							<Fragment>
								<div className="banner hovered card-category">
									<img
										src={category.principal_photo}
										alt=""
										class="img-card-category"
									/>
									<div className="banner-content top left">
										<h2 className="text-shadow color-none ultra-bold mb-5 text-left">
											{category.name}
										</h2>
										<span className="action-link flex-center">
											<span>Voir collection</span>
											<svg
												width="10"
												height="13"
												viewBox="0 0 10 13"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1.96 12.3345C1.64597 12.3327 1.33587 12.2645 1.05 12.1345C0.740826 11.9983 0.477413 11.7759 0.291223 11.4939C0.105032 11.212 0.00393305 10.8824 0 10.5445V2.12454C0.00393305 1.78669 0.105032 1.45711 0.291223 1.17516C0.477413 0.893215 0.740826 0.670821 1.05 0.534542C1.40574 0.366509 1.80153 0.30179 2.19227 0.347759C2.583 0.393728 2.95297 0.548536 3.26 0.794542L8.36 5.00454C8.55999 5.16376 8.7215 5.36603 8.83252 5.5963C8.94353 5.82657 9.00118 6.07891 9.00118 6.33454C9.00118 6.59017 8.94353 6.84251 8.83252 7.07278C8.7215 7.30305 8.55999 7.50532 8.36 7.66454L3.26 11.8745C2.89238 12.1727 2.43331 12.3351 1.96 12.3345ZM1.96 2.33454V10.2345L6.82 6.33454L1.96 2.33454Z"
													fill="black"
												></path>
											</svg>
										</span>
									</div>
								</div>
							</Fragment>
						</Link>
					))}
				</div>

				<div>
					<h3 className="text-center">Des dimensions pour chaque voyage</h3>
					<div className="images-dimension">
						<div className="div-image-dimension">
							<img src={weekend} className="image-dimension" />
						</div>
						<div className="div-image-dimension">
							<img src={baroudeur} className="image-dimension" />
						</div>
						<div className="div-image-dimension">
							<img src={globetrotteur} className="image-dimension" />
						</div>
						<div className="div-image-dimension">
							<img src={touriste} className="image-dimension" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
