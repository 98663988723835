import React from "react";
import { Link } from "react-router-dom";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import arrow from "../assets/images/right-arrow.png";
import background from "../assets/images/aboutus-background.png";

function AboutUs() {
	return (
		<div>
			<Header />
			<div class="breadcrumbs">
				<a href="/" class="links-breadcrumbs m-5">
					Accueil
				</a>
				<img src={arrow} class="arrow-breadcrumbs m-5" />
				<p class="m-5">A propos de nous</p>
			</div>
			<div className="about-us">
				<h2 style={{ marginBottom: '20px' }}>A propos de nous</h2>
					<h1>GO GREEN !</h1>
					<p>Après une carrière de plus de 20 ans dans le secteur de la bagagerie, j’ai eu envie d’accomplir mon rêve et de créer une marque où je pourrais pleinement m’exprimer.</p>
					<p>C’est ainsi que France Bag est né. Depuis plus de 10 ans, nos gammes colorées et élégantes marquent la différence par leur style tendance, leur haut niveau de qualité et leur caractère innovant.</p>
					<p>Cette année, nous sommes allés plus loin dans notre réponse aux attentes du consommateur en y alliant notre volonté de nous engager en matière de développement durable.</p>
					<p>Conscients de l’évolution des modes de consommation et sensibles à notre environnement, nous sommes fiers de vous présenter</p>
					<p>«Green Tendance».</p>
					<p>Conçue à partir de plastique recyclé, «Green Tendance» représente un réel engagement de notre part pour que le voyage soit une belle façon d’être durable.</p>
					<p>Mon équipe et moi-même continuons au quotidien de créer, d’innover et de développer des collections pour toujours plus vous satisfaire.</p>
					<p>N’hésitez pas à nous contacter pour vos futurs projets. Bonne route et bon voyage !</p>
					<div class="text-center">
					<img src={background} className="about-us-image"/>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AboutUs;
