import React from "react";
import { Link } from "react-router-dom";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import { getProducts } from "../assets/data";

function Products() {
	let products = getProducts();
	return (
		<div>
			<Header />
			{products.map((product) => (
				<Link to={`/products/${product.name}`} key={product.reference}>
					{product.name}
				</Link>
			))}
			<Footer />
		</div>
	);
}

export default Products;
