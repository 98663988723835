import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-green-travel-removebg-preview.png";
import "../../assets/css/Footer.css";
import map from "../../assets/images/google-maps.png";

function Footer() {
	return (
		<div>
			<footer className="page-footer">
				<img src={logo} alt="green-travel-logo" className="header-logo" />

				<div className="footer content">
					<a className="footer-links" href="https://waze.com/ul?ll=48.953884,2.4802637&amp;navigate=yes">
						9 rue Jean Lolive
						<br />
						93600 Aulnay sous bois
					</a>
					<br />
					<a className="footer-links" href="tel:+33148655555"> + 33 (1) 48 65 55 55</a>
					<br />
					<a className="footer-links" href="mailto:info@francebag.fr"> info@francebag.fr</a>
					<br />
					<Link className="footer-links" to="/mentions-legales">Mentions légales</Link>
					<br />
					<div style={{ textAlign: 'center' }}>
						<small className="copyright">
							© 2021 Green Travel. Tous droits reservés.
						</small>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Footer;
