import React from "react";
import { Link } from "react-router-dom";
import burgerMenu from "../../assets/images/menu.png";
import crossMenu from "../../assets/images/close.png";
import logo from '../../assets/images/logo-green-travel.png'
import CustomLink from "./CustomLink.tsx";
import { elastic as Menu } from "react-burger-menu";
import '../../assets/css/Header.css';

function Header() {
	function openMenu(e) {
		e.preventDefault();
		document.querySelector(".menu-items").style.display = "block";
	}

	function closeMenu(e) {
		e.preventDefault();
		document.querySelector(".menu-items").style.display = "none";
	}
	return (
		<header>
			<nav>
				<div className="header-mobile">
				<Menu customBurgerIcon={ <img src={burgerMenu} /> }>
					<a id="home" className="menu-item" href="/">Accueil</a>
					<Link to="/categories/valise-rigides">Valise rigides</Link>
					<Link to="/categories/valise-souples">Valise souples</Link>
					<Link to="/about-us">A propos de nous</Link>
				</Menu>
		
				</div>
			

				<ul class="menu-items">
					<li>
						<Link to="/">Accueil</Link>
					</li>
					<li>
						<Link to="/categories/valise-rigides">Valise rigides</Link>
					</li>
					<li>
						<Link to="/categories/valise-souples">Valise souples</Link>
					</li>
					<li>
						<Link to="/about-us">A propos de nous</Link>
					</li>
					<img
						src={crossMenu}
						alt="logo-burger-menu"
						class="burger-menu"
						onClick={closeMenu}
					/>
				</ul>
				<div
					style={{
						width: "100%",
						textAlign: "center",
						marginTop: "15px",
						marginBottom: "15px",
					}}
				>
					<Link to="/">
						<img
							src={logo}
							alt="green-travel-logo"
							class="header-logo"
						/>
					</Link>
				</div>
				<ul className="navbar-desktop">
					<li>
						<CustomLink to="/">Accueil</CustomLink>
					</li>
					<li>
						<CustomLink to="/categories/valise-rigides">Valise rigides</CustomLink>
					</li>
					<li>
						<CustomLink to="/categories/valise-souples">Valise souples</CustomLink>
					</li>
					<li>
						<CustomLink to="/about-us">A propos de nous</CustomLink>
					</li>
				</ul>
			</nav>
		</header>
	);
}

export default Header;
