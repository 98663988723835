import { Link, useParams } from "react-router-dom";
import {
	getCategories,
	getCategory,
	getCategoryProducts,
} from "../assets/data";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import arrow from "../assets/images/right-arrow.png";

function Category() {
	let params = useParams();
	let category = getCategory(params.categoryName);
	let products = getCategoryProducts(params.categoryName);

	return (
		<div>
			<Header />
			<div class="breadcrumbs">
				<a href="/" class="links-breadcrumbs m-5">
					Accueil
				</a>
				<img src={arrow} class="arrow-breadcrumbs m-5" />
				<p class="m-5">{category.name}</p>
			</div>
			<div class="banner-title">
				<h1 class="banner-title-text">{category.name}</h1>
			</div>
			<div class="product-cards">
				{products.map((product) =>
					product.variants.map((variant) => (
						<div class="product-card">
							<Link
								to={`/products/${product.name}?color=${variant.url_rewrite}`}
								key={product.reference}
								style={{ textDecoration: "none", color: "#030303" }}
							>
								<img src={variant.principal_photo} style={{ width: '320px', background: '#F9F9F9', marginTop: '5px', borderRadius: '5px' }}/>
								<p style={{ textAlign: "center" }}>
									{product.name}
									<br />
									{variant.color}
								</p>
							</Link>
						</div>
					)),
				)}
			</div>
			<Footer />
		</div>
	);
}

export default Category;
