import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import getProductVariant, { getProduct } from "../assets/data";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import arrow from "../assets/images/right-arrow.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Product() {
	let params = useParams();
	let product = getProduct(params.productName);
	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	let query = useQuery();

	let productVariant = getProductVariant(
		params.productName,
		query.get("color"),
	);
	return (
		<div>
			<Header />
			<div class="breadcrumbs">
				<a href="/" class="links-breadcrumbs m-5">
					Accueil
				</a>
				<img src={arrow} class="arrow-breadcrumbs m-5" />
				<Link
					to={`/categories/${product.category_name}`}
					className="m-5 link-breadcrumb"
				>
					{product.category_display_name}
				</Link>
				<img src={arrow} class="arrow-breadcrumbs m-5" />
				<p class="m-5">{product.name}</p>
			</div>

			<div class="product-item">
				<div className="carousel-product">
					<Carousel width={"260px"}>
						{productVariant.pictures.map((picture) => (
							<div>
								<img src={picture} />
							</div>
						))}
					</Carousel>
				</div>

				<div className="product-text-desc">
					<p>{product.category_display_name} </p>
					<h1 className="product-name">
						{product.name} - {productVariant.color}
					</h1>
					<div className="product-colors">
						{product.colors.map((color) => (
							<Link
								to={`/products/${product.name}?color=${color}`}
								key={product.reference}
								style={{
									textDecoration: "none",
									color: "#030303",
									borderRadius: "50px",
								}}
								className={`${color} badge`}
							></Link>
						))}
					</div>
					

					<div class="story">{product.story}</div>

					<div className="features">
						<h4>Dimensions</h4>
						<table>
							<thead>
								<tr>
									<th className="th">Format</th>
									<th className="th">Poids Kg</th>
									<th className="th">Volume L</th>
									<th className="th">Taille cm</th>
								</tr>
							</thead>
							<tbody>
							{product.dimensions.map((dimension) => (
								<tr>
									<td>{ dimension.format }</td>
									<td>{ dimension.weight }</td>
									<td>{ dimension.volume }</td>
									<td>{ dimension.size }</td>	
								</tr>
							))}
								
							</tbody>
						</table>
					</div>
					
					<div className="features">
						<h4>Fonctionnalités</h4>
						{product.features.map((feature) => (
							<p>{feature}</p>
						))}
					</div>
				</div>
			</div>
			
			<div className="argument-cards">
				{product.arguments.map((argument) => (
					<div className="argument-card">
						<img src={argument.picture} className="argument-picture" />
						<h5 class="argument-title">{argument.title}</h5>
						<p>{argument.description}</p>
					</div>
				))}
			</div>
			<Footer />
		</div>
	);
}

export default Product;
